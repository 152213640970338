<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";

export default {
  name: "WarehouseItems",
  components: { DataTableComponent },
  data() {
    return {
      table: {
        modal: "Warehouse Item",
        path: "WarehouseItems",
        headers: [
          {
            text: this.$i18n.t("Code"),
            value: "item_code"
          },
          {
            text: this.$i18n.t("Name"),
            value: "item_name"
          },
          {
            text: this.$i18n.t("Item Category"),
            value: "item_category"
          },
          {
            text: this.$i18n.t("Quantity"),
            value: "quantity"
          },
          {
            text: this.$i18n.t("Transactions"),
            value: "item_transaction_count"
          }
        ],
        title: this.$i18n.t("Warehouse Items"),
        apiEndPoints: {
          list: "warehouses/getAllWarehouseItemsQuantities"
          //   create: "warehouses_transactions/show/:id",
          //   edit: "taxes/update",
          //   delete: "taxes/destroy"
        },
        editedItem: {},
        permissions: {
          list: "list-transactions"
          //   add: "add-transactions",
          //   edit: "edit-transactions",
          //   delete: "delete-transactions",
          //   print: "print-transactions",
          //   view: "view-transactions",
        }
      }
    };
  }
};
</script>
<style lang="scss" scoped></style>
